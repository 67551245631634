@charset "UTF-8";
.assessment-question {
  border-bottom: 1px solid #6D778B;
  margin-bottom: 20px;
  padding-bottom: 20px;
  width: 95%;
}
.assessment-question p {
  margin-bottom: 0;
}

.assessment-label {
  font-weight: bold;
}

.question-slider .slider {
  width: 100%;
  height: 10px;
  border-radius: 10px;
  background: #6D778B;
  position: relative;
  margin: 10px 0;
}
.question-slider .circle {
  background: #b02e0c;
  height: 20px;
  border-radius: 20px;
  width: 20px;
  position: absolute;
  top: -5px;
  left: -10px;
}
.question-slider .labels, .question-slider .numbers {
  display: flex;
  justify-content: space-between;
}

.choices {
  padding: 0;
  margin: 0 0 0 15px;
}
.choices li {
  opacity: 0.7;
  padding-left: 10px;
  list-style-type: "⬜️";
}
.choices .checked {
  font-weight: bold;
  opacity: 1;
  list-style-type: "✅";
}

.questions li:last-child .assessment-question {
  border: none !important;
}
