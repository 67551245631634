.assessment-container {
  clear: both;
  margin: 0;
  padding: 0;
  width: 100%;
}
.assessment-container > ul {
  margin: 0;
  padding: 0;
  display: flex;
  width: 100%;
}
.assessment-container > ul > li {
  flex: 1 0 0;
}
